<template>
  <div :class="$vuetify.breakpoint.name === 'lg' ? 'pr-5 pl-5' : 'px-8'">

    <div class="mt-5">

      <Breadcrumbs :title="`Add New Questions`" :items="items"/>
      <Snackbar ref="snackbar"/>

      <div class="mb-8">
        <ValidationObserver ref="observer">
          <v-form>
            <div>
              <v-expansion-panels flat v-model="panel">
                <v-expansion-panel expand v-model="panel" v-for="(item,i) in questions" :key="i" class="mb-3 rounded-lg" style="border: 1px solid #ddd">
                  <v-expansion-panel-header>
                    <span class="font-weight-bold text-h6"> Number {{ i + 1 }} </span>
                    <v-spacer></v-spacer>
                    <div class="text-right">
                      <v-btn
                        icon
                        small
                        @click.stop="confirmDelete(i)">
                        <v-icon color="red">mdi-trash-can-outline</v-icon>
                      </v-btn>
                    </div>
                  </v-expansion-panel-header>
                  <v-divider></v-divider>
                  <v-expansion-panel-content>
                    <div class="mt-7">
                      <ValidationProvider :name="item.instruction" rules="required" v-slot="{ errors }">
                        <v-text-field
                          outlined
                          dense
                          v-model="item.instruction"
                          :error-messages="errors"
                          label="Instructions"
                          autocomplete="off"
                          color="#F05326">
                        </v-text-field>
                      </ValidationProvider>
                      <ValidationProvider :name="item.question" rules="required" v-slot="{ errors }">
                        <v-text-field
                          outlined
                          dense
                          v-model="item.question"
                          :error-messages="errors"
                          label="Question"
                          autocomplete="off"
                          color="#F05326">
                        </v-text-field>
                      </ValidationProvider>
                    </div>

                    <div v-for="(item,index) in item.options" :key="index">
                      <ValidationProvider :name="item.text" rules="required" v-slot="{ errors }">
                        <v-text-field
                          outlined
                          dense
                          :readonly="!item.isEditing"
                          :error-messages="errors"
                          v-model="item.text"
                          :label="`Option ${index + 1}`"
                          autocomplete="off"
                          color="#F05326">
                          <!-- <template v-slot:append>
                            <v-slide-x-reverse-transition mode="out-in">
                              <v-icon
                                :key="`icon-${item.isEditing}`"
                                :color="item.isEditing ? 'success' : 'info'"
                                @click="item.isEditing == true ? add_data_correct(i,index,item.text) : edit_data_correct(i,index, item.text)"
                                v-text="item.isEditing ? 'mdi-check-outline' : 'mdi-circle-edit-outline'"
                              ></v-icon>
                            </v-slide-x-reverse-transition>
                          </template> -->
                          <!-- <template v-if="isDuplicate(i,item)" v-slot:prepend>
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon color="warning" size="30" v-bind="attrs" v-on="on">
                                  mdi-alert-circle-outline
                                </v-icon>
                              </template>
                              <span>Peringatan! Option duplikat</span>
                            </v-tooltip>
                          </template> -->
                          <v-icon
                            @click="removeOption(i,index)"
                            color="red"
                            slot="append-outer">
                            mdi-close
                          </v-icon>
                        </v-text-field>
                      </ValidationProvider>
                    </div>

                    <div>
                      <v-btn 
                        @click="addOptions(i)"
                        color="#F05326"
                        text 
                        dense 
                        small 
                        elevation="0">
                        <v-icon left>mdi-plus</v-icon>
                        Add Options
                      </v-btn>
                    </div>

                    <div class="mt-5">
                      <!-- <ValidationProvider :name="item.correct_answer" rules="required" v-slot="{ errors }"> -->
                          <!-- :error-messages="errors" -->
                        <v-select
                          v-model="item.correct_answer"
                          label="Correct Answer"
                          multiple
                          :items="item.options"
                          item-text="text"
                          item-value="text"
                          outlined
                          dense
                          small-chips
                          color="#F05326">
                          <template v-slot:selection="{ attrs, item, parent, selected }">
                            <v-chip
                              v-if="item"
                              v-bind="attrs"
                              color="grey lighten-2"
                              :input-value="selected"
                              small>
                              <span class="pr-2">
                                {{ item.text }}
                              </span>
                              <v-icon
                                x-small
                                color="grey darken-1"
                                @click="parent.selectItem(item)">
                                mdi-close-circle
                              </v-icon>
                            </v-chip>
                          </template>
                        </v-select>
                      <!-- </ValidationProvider> -->
                      <ValidationProvider :name="item.correct_explan" rules="required" v-slot="{ errors }">
                        <v-text-field
                          outlined
                          dense
                          v-model="item.correct_explan"
                          :error-messages="errors"
                          label="Correct Answer Explanation"
                          autocomplete="off"
                          color="#F05326">
                        </v-text-field>
                      </ValidationProvider>
                      <ValidationProvider :name="item.incorrect_explan" rules="required" v-slot="{ errors }">
                        <v-text-field
                          outlined
                          dense
                          v-model="item.incorrect_explan"
                          :error-messages="errors"
                          label="Incorrect Answer Explanation"
                          autocomplete="off"
                          color="#F05326">
                        </v-text-field>
                      </ValidationProvider>
                      <ValidationProvider :name="`${item.point}`" rules="required" v-slot="{ errors }">
                        <v-text-field
                          outlined
                          dense
                          v-model="item.point"
                          :error-messages="errors"
                          label="Point"
                          type="number"
                          autocomplete="off"
                          color="#F05326">
                        </v-text-field>
                      </ValidationProvider>
                    </div>

                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>
            
            <div class="mt-7">
              <v-btn 
                outlined
                dense
                :loading="$store.state.process.run"
                :disabled="$store.state.process.run"
                class="text-capitalize"
                color="#F05326" 
                @click="addQuestion()">
                <v-icon left>mdi-plus</v-icon>
                Add Question
              </v-btn>
              <v-btn
                dense
                depressed
                @click="setData"
                :loading="$store.state.process.run"
                :disabled="$store.state.process.run || questions.length < 1"
                class="text-capitalize white--text float-right"
                color="#F05326">
                Save
              </v-btn>
              <v-btn
                dense
                depressed
                :loading="$store.state.process.run"
                :disabled="$store.state.process.run || questions.length < 1"
                class="text-capitalize black--text mr-5 float-right"
                @click="$router.push(`/questions/${$route.params.id_course}/${$route.params.id_topic}/${$route.params.id_activity}/${$route.params.activity_type}`)"
                color="#F5F5F5">
                Cancel
              </v-btn>
            </div>
          </v-form>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
import { get, post, put , destroy } from '@/service/Axios'
import Breadcrumbs from '@/components/Breadcrumbs.vue'
import Snackbar from '@/components/Snackbar.vue'
export default {
  data() {
    return {
      /*
       * ANSWER multiple_choice
       */
      dataAnswer: [],
      questions: [
        // {
        //   id: "",
        //   name: "Nomor 1",
        //   instruction: "",
        //   question: "",
        //   correct_answer: [],
        //   correct_explan: "",
        //   incorrect_explan: "",
        //   point: "",
        //   options:[
        //     {
        //       title: "Jawaban A",
        //       isEditing: true,
        //       text: "",
        //     }
        //   ]
        // },
      ],
      /*
       * ANSWER END 
       */
      panel: 0,
      items: [
        {
          text: 'Menyusun Kata',
          disabled: false,
          href: `/questions/${this.$route.params.id_course}/${this.$route.params.id_topic}/${this.$route.params.id_activity}/${this.$route.params.activity_type}`,
        },
        {
          text: 'Add New Questions',
          disabled: true,
          href: '/activity/create',
        }
      ],
      process:{
        run: false
      }
    }   
  },
  watch: {},
  computed:{},
  components: { Breadcrumbs, Snackbar },
  mounted(){
    if(this.$route.params.id_question){
      this.fetch()
    }else {
      this.questions.push({
        id: "",
        name: `Nomor ${this.questions.length + 1}`,
        instruction: "",
        question: "",
        correct_answer: [],
        correct_explan: "",
        incorrect_explan: "",
        point: 0,
        options: [
          {
            title: "Jawaban A",
            isEditing: true,
            text: "",
          }
        ]
      });
    }
  },
  created(){},
  methods:{
    async fetch(){
      this.$store.state.process.run = true
      await get(`bank/v1/question/detail/${this.$route.params.id_question}`)
      .then((response) => {
        let res = response.data
        console.log(res);
        if(res.status == 200){
          this.$store.state.process.run = false
          let question = {
            id: res.data.id,
            instruction : res.data.instruction.text,
            question : res.data.question.text,
            correct_answer : res.data.correct_answer,
            correct_explan : res.data.explanation.true.text,
            incorrect_explan : res.data.explanation.false.text,
            point : res.data.point,
            options: res.data.options
          }
          // console.log(question);
          this.questions.push(question)
        }else {
          this.$store.state.process.run = false
        }
      })
    },

    /*
     * METHOD QUESTION
     */
    addQuestion() {
      this.questions.push({
        id: "",
        name: `Nomor ${this.questions.length + 1}`,
        instruction: "",
        question: "",
        correct_answer: [],
        correct_explan: "",
        incorrect_explan: "",
        point: 0,
        options: [
          {
            title: "Jawaban A",
            isEditing: true,
            text: "",
          }
        ]
      });
    },
    addOptions(idx){
      this.questions[idx].options.push({
        title: "Jawaban A",
        isEditing: true,
        text: "",
      })
    },
    confirmDelete(idx){
      this.questions.splice(idx,1)
    },
    removeOption(idx,index){
      this.questions[idx].options.splice(index, 1)
    },
    /*
    * METHOD QUESTION END
    */
    /*
    * SAVE DATA
    */
    isDuplicate(i, opt) {
      let data = this.questions[i].options.filter((item, index) => item.text.toLowerCase() == opt.text.toLowerCase()).length != 1
      return data
    },
    add_data_correct(i,index, value){
      this.questions[i].options = this.questions[i].options.filter((opt) => {
        opt.text = opt.text.trim();
        return opt.text != "";
      });
      this.questions[i].options.forEach((opt) => (opt.isEditing = false));
    },
    edit_data_correct(i,index,value){

      this.add_data_correct(i,index,value);
      this.questions[i].options.forEach((opt) => (opt.isEditing = false));
      this.questions[i].options[index].isEditing = true
    },
    /**
    * METHOD SAVE DATA
    */
    async setData(){
      const isValid = await this.$refs.observer.validate()
      if (this.questions.length < 1) {
        this.$store.state.process.run = false
        this.$refs.snackbar.open("error", `Soal tidak ditemukan`);
      }else{
        if (isValid) {
          this.$store.state.process.run = true
          let data = {}
          this.questions.forEach((item,i) => {
            this.$store.state.process.run = true
            if(item.correct_answer.length < 1){
              this.$store.state.process.run = false
              this.$refs.snackbar.open("error", `Correct Answer masih belum terisi`);
            }else {
              data.options = []
              item.options.forEach((opt,index) => {
                data.options.push({
                  media: "",
                  text: opt.text
                })
              })
              data.instruction_text = item.instruction;
              data.instruction_media = "";
              data.type = "multiple_choice";
              data.question_text = item.question;
              data.question_media = "";
              data.point = parseInt(item.point);
              data.correct_answer = item.correct_answer;
              data.status = "publish"
              data.explanation = {
                true_text: item.correct_explan,
                true_media: "",
                false_text: item.incorrect_explan,
                false_media: ""
              }
              if (item.id !== "") {
                this.save(`update`, `bank/v1/question/${this.$route.params.id_question}`, `api/v1/question/update/${this.$route.params.id_topic}/${this.$route.params.id_activity}/${this.$route.params.id_question}` , data)
              }else {
                this.save(`create`, `bank/v1/question/`, `api/v1/question/add/${this.$route.params.id_topic}/${this.$route.params.id_activity}`, data)
              }
            }
          })
        }else{
          this.$store.state.process.run = false
          this.$refs.snackbar.open("error", `Form Belum Valid`);
        }
      }
    },
    async save(params, url_question, url_activity, data_send){
      this.$store.state.process.run = true
      if(params == "update"){
        await put(url_question,{
          data: data_send
        })
        .then((response) => {
          let res = response.data
          if (res.status == 200) {
            put(url_activity,{
              data: {
                id: res.data.id,
                is_public: false,
                is_paid: true
              }
            })
            .then(response => {
              let res = response.data
              if (res.status == 200) {
                this.$refs.snackbar.open("#4CAF50", `New Question Updated Succesfully`);
                setTimeout(() => {
                  this.$store.state.process.run = false
                  this.$router.push(`/questions/${this.$route.params.id_course}/${this.$route.params.id_topic}/${this.$route.params.id_activity}/${this.$route.params.activity_type}`)
                }, 1000);
              }else{
                this.$store.state.process.run = false
                this.$refs.snackbar.open("error", `New Question Updated Failed`);
              }
            })
          }else{
            this.$store.state.process.run = false
            this.$refs.snackbar.open("error", `New Question Updated Failed`);
          }
        })
      }else {
        await post(url_question,{
          data: data_send
        })
        .then((response) => {
          let res = response.data
          if (res.status == 200) {
            post(url_activity,{
              data: {
                id: res.data.id,
                is_public: false,
                is_paid: true
              }
            })
            .then(response => {
              let res = response.data
              if (res.status == 200) {
                this.$refs.snackbar.open("#4CAF50", `New Question Added Succesfully`);
                setTimeout(() => {
                  this.$store.state.process.run = false
                  this.$router.push(`/questions/${this.$route.params.id_course}/${this.$route.params.id_topic}/${this.$route.params.id_activity}/${this.$route.params.activity_type}`)
                }, 1000);
              }else{
                this.$store.state.process.run = false
                this.$refs.snackbar.open("error", `New Question Added Failed`);
              }
            })
          }else{
            this.$store.state.process.run = false
            this.$refs.snackbar.open("error", `New Question Added Failed`);
          }
        })
      }
    },
    /**
    * METHOD SAVE DATA END
    */
  }
}
</script>