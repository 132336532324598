var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$vuetify.breakpoint.name === 'lg' ? 'pr-5 pl-5' : 'px-8'},[_c('div',{staticClass:"mt-5"},[_c('Breadcrumbs',{attrs:{"title":"Add New Questions","items":_vm.items}}),_c('Snackbar',{ref:"snackbar"}),_c('div',{staticClass:"mb-8"},[_c('ValidationObserver',{ref:"observer"},[_c('v-form',[_c('div',[_c('v-expansion-panels',{attrs:{"flat":""},model:{value:(_vm.panel),callback:function ($$v) {_vm.panel=$$v},expression:"panel"}},_vm._l((_vm.questions),function(item,i){return _c('v-expansion-panel',{key:i,staticClass:"mb-3 rounded-lg",staticStyle:{"border":"1px solid #ddd"},attrs:{"expand":""},model:{value:(_vm.panel),callback:function ($$v) {_vm.panel=$$v},expression:"panel"}},[_c('v-expansion-panel-header',[_c('span',{staticClass:"font-weight-bold text-h6"},[_vm._v(" Number "+_vm._s(i + 1)+" ")]),_c('v-spacer'),_c('div',{staticClass:"text-right"},[_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.confirmDelete(i)}}},[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-trash-can-outline")])],1)],1)],1),_c('v-divider'),_c('v-expansion-panel-content',[_c('div',{staticClass:"mt-7"},[_c('ValidationProvider',{attrs:{"name":item.instruction,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","dense":"","error-messages":errors,"label":"Instructions","autocomplete":"off","color":"#F05326"},model:{value:(item.instruction),callback:function ($$v) {_vm.$set(item, "instruction", $$v)},expression:"item.instruction"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":item.question,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","dense":"","error-messages":errors,"label":"Question","autocomplete":"off","color":"#F05326"},model:{value:(item.question),callback:function ($$v) {_vm.$set(item, "question", $$v)},expression:"item.question"}})]}}],null,true)})],1),_vm._l((item.options),function(item,index){return _c('div',{key:index},[_c('ValidationProvider',{attrs:{"name":item.text,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","dense":"","readonly":!item.isEditing,"error-messages":errors,"label":("Option " + (index + 1)),"autocomplete":"off","color":"#F05326"},model:{value:(item.text),callback:function ($$v) {_vm.$set(item, "text", $$v)},expression:"item.text"}},[_c('v-icon',{attrs:{"slot":"append-outer","color":"red"},on:{"click":function($event){return _vm.removeOption(i,index)}},slot:"append-outer"},[_vm._v(" mdi-close ")])],1)]}}],null,true)})],1)}),_c('div',[_c('v-btn',{attrs:{"color":"#F05326","text":"","dense":"","small":"","elevation":"0"},on:{"click":function($event){return _vm.addOptions(i)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" Add Options ")],1)],1),_c('div',{staticClass:"mt-5"},[_c('v-select',{attrs:{"label":"Correct Answer","multiple":"","items":item.options,"item-text":"text","item-value":"text","outlined":"","dense":"","small-chips":"","color":"#F05326"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var parent = ref.parent;
var selected = ref.selected;
return [(item)?_c('v-chip',_vm._b({attrs:{"color":"grey lighten-2","input-value":selected,"small":""}},'v-chip',attrs,false),[_c('span',{staticClass:"pr-2"},[_vm._v(" "+_vm._s(item.text)+" ")]),_c('v-icon',{attrs:{"x-small":"","color":"grey darken-1"},on:{"click":function($event){return parent.selectItem(item)}}},[_vm._v(" mdi-close-circle ")])],1):_vm._e()]}}],null,true),model:{value:(item.correct_answer),callback:function ($$v) {_vm.$set(item, "correct_answer", $$v)},expression:"item.correct_answer"}}),_c('ValidationProvider',{attrs:{"name":item.correct_explan,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","dense":"","error-messages":errors,"label":"Correct Answer Explanation","autocomplete":"off","color":"#F05326"},model:{value:(item.correct_explan),callback:function ($$v) {_vm.$set(item, "correct_explan", $$v)},expression:"item.correct_explan"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":item.incorrect_explan,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","dense":"","error-messages":errors,"label":"Incorrect Answer Explanation","autocomplete":"off","color":"#F05326"},model:{value:(item.incorrect_explan),callback:function ($$v) {_vm.$set(item, "incorrect_explan", $$v)},expression:"item.incorrect_explan"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":("" + (item.point)),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","dense":"","error-messages":errors,"label":"Point","type":"number","autocomplete":"off","color":"#F05326"},model:{value:(item.point),callback:function ($$v) {_vm.$set(item, "point", $$v)},expression:"item.point"}})]}}],null,true)})],1)],2)],1)}),1)],1),_c('div',{staticClass:"mt-7"},[_c('v-btn',{staticClass:"text-capitalize",attrs:{"outlined":"","dense":"","loading":_vm.$store.state.process.run,"disabled":_vm.$store.state.process.run,"color":"#F05326"},on:{"click":function($event){return _vm.addQuestion()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" Add Question ")],1),_c('v-btn',{staticClass:"text-capitalize white--text float-right",attrs:{"dense":"","depressed":"","loading":_vm.$store.state.process.run,"disabled":_vm.$store.state.process.run || _vm.questions.length < 1,"color":"#F05326"},on:{"click":_vm.setData}},[_vm._v(" Save ")]),_c('v-btn',{staticClass:"text-capitalize black--text mr-5 float-right",attrs:{"dense":"","depressed":"","loading":_vm.$store.state.process.run,"disabled":_vm.$store.state.process.run || _vm.questions.length < 1,"color":"#F5F5F5"},on:{"click":function($event){return _vm.$router.push(("/questions/" + (_vm.$route.params.id_course) + "/" + (_vm.$route.params.id_topic) + "/" + (_vm.$route.params.id_activity) + "/" + (_vm.$route.params.activity_type)))}}},[_vm._v(" Cancel ")])],1)])],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }